<template>
  <div>
    <b-row class="justify-content-center">
      <b-col cols="8">
        <hr>
        <h1 class="display-4">{{$t("ranking.title")}}</h1>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col cols="8">
        <h3 v-if="this.updateTime">{{$t("ranking.update-time")}}: {{this.updateTimeFmt}} </h3>
        <b-skeleton v-if="!this.updateTime" width="100%" animation="throb" type="button"></b-skeleton>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col cols="8">
        <b-dropdown
            :text="options[selected].name"
            block
            variant="dark"
            class="m-2"
            menu-class="w-100"
        >
          <b-dropdown-item class="text-center" variant="dark" type="dark" @click="selected = index" v-for="(option,index) in options" :key="index">{{option.name}}</b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col cols="8">
        <b-table show-empty :empty-text="$t('ranking.empty')" table-variant="dark" class="mt-3 ranking-table" hover :items="items" :fields="fields" responsive :busy="isBusy">

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner> <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(name)="data">
            <div @click="linkToStats(data.item.uuid,data.item.platform)" class="pointer">
              <b-avatar :src="data.item.avatar"></b-avatar>
              {{data.value}}
              <strong v-html="renderPlatform(data.item.platform)"></strong>
            </div>
          </template>

        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  name: "Ranking",
  mounted() {
    this.$store.commit("NavigationState/SET_PAGE",this.$route.name)
    document.title = this.$t('ranking.title').toString()
    axios.post(`${this.apiURL}ubi/leaderboard`,{
      platform: "ALL"
    }).then(response => {
      this.rankData.all = response.data.payload
      this.successCount++
    })
    axios.post(`${this.apiURL}ubi/leaderboard`,{
      platform: "PC"
    }).then(response => {
      this.rankData.pc = response.data.payload
      this.successCount++
    })
    axios.post(`${this.apiURL}ubi/leaderboard`,{
      platform: "PSN"
    }).then(response => {
      this.rankData.ps = response.data.payload
      this.successCount++
    })
    axios.post(`${this.apiURL}ubi/leaderboard`,{
      platform: "XBL"
    }).then(response => {
      this.rankData.xbox = response.data.payload
      this.successCount++
    })
  },
  data() {
    return {
      platformMap: {
        xbox: "XBL",
        playstation: "PSN",
        pc: "PC"
      },
      successCount: 0,
      selected: 0,
      rankData: {
        all: [],
        pc: [],
        ps: [],
        xbox: []
      },
      fields: [{
        key : "id",
        label : this.$t('ranking.ranking.id'),
        thStyle : {
          width : "3%"
        },
      },{
        key: "name",
        label: this.$t('ranking.ranking.name'),
        thStyle : {
          width : "91%"
        },
      },{
        key: "score",
        label: this.$t('ranking.ranking.score'),
        thStyle : {
          width : "6%"
        },
      }],
      options: [
        {name: this.$t('ranking.all'), item: "all"},
        {name: this.$t('ranking.pc'), item: "pc"},
        {name: this.$t('ranking.ps'), item: "ps"},
        {name: this.$t('ranking.xbox'), item: "xbox"},
      ]
    }
  },
  computed: {
    ...mapState("LanguageState",["language"]),
    ...mapState("PlatformState",["platform","platforms"]),
    ...mapState("ApiState",["apiURL"]),
    updateTimeFmt() {
      if (!this.updateTime)
        return ""
      return this.$d(this.updateTime, "short")
    },
    updateTime() {
      if (!this.rankData[this.options[this.selected].item])
        return ""
      return this.rankData[this.options[this.selected].item].modified * 1000
    },
    items() {
      if (!this.rankData[this.options[this.selected].item])
        return []
      let ret = []
      for (let index in this.rankData[this.options[this.selected].item].board) {
        let player = this.rankData[this.options[this.selected].item].board[index]
        ret.push({
          id: parseInt(index) + 1,
          name: player.info.nickname,
          score: player.mmr,
          uuid: player.info.userId,
          avatar: player.info.avatar,
          platform: player.info.platform
        })
      }
      return ret
    },

    isBusy(){ return this.successCount !== 4},
  },
  methods: {
    updateFields() {
      this.fields = [{
        key : "id",
        label : this.$t('ranking.ranking.id'),
        thStyle : {
          width : "3%"
        },
        "primary-key": true,
      },{
        key: "name",
        label: this.$t('ranking.ranking.name'),
        thStyle : {
          width : "91%"
        },
      },{
        key: "score",
        label: this.$t('ranking.ranking.score'),
        thStyle : {
          width : "6%"
        },
      }]
    },
    linkToStats(uuid,platform) {
      this.$router.push({
        name: "Overview",
        params: {
          uuid: uuid,
          platform: this.platformMap[platform]
        }
      })
    },
    renderPlatform(p) {
      return this.platforms.find(platform => platform.value === this.platformMap[p]).html
    }
  },
  watch: {
    language() {
      this.updateFields()
    }
  },
}
</script>

<style scoped>

h1,h3 {
  color: white;
}

.ranking-table {
  opacity: 0.8;
}

.pointer{
  cursor: pointer;
}
</style>
<template>
  <div>
    <b-row class="justify-content-center">
      <b-col>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
              v-model="selected"
              :options="options"
              :aria-describedby="ariaDescribedby"
              class="m-2"
              button-variant="dark"
              buttons
              size="lg"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="auto">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
              v-model="operatorTypeSelected"
              :options="operatorTypeOptions"
              :aria-describedby="ariaDescribedby"
              class="m-2"
              button-variant="dark"
              buttons
              size="lg"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col>
        <b-table show-empty :empty-text="$t('stats.operators.empty')" :filter="{category: operatorTypeSelected,name: operatorName}" :filter-included-fields="['category','name']"  table-variant="dark" class="mt-3 ranking-table" hover :items="operatorItems" :fields="operatorFields" responsive :busy="isBusy">

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner> <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(name)="data">

            <b-img v-if="data.value !== ''" width=35 height="auto" :src="requireAvatar(data.value.toLowerCase())"></b-img>
            {{data.value}}
          </template>

        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Operators",
  props: ['data'],
  data(){
    return {
      operatorTypeSelected: "",
      needUpdate: false,
      selected: "all",
      operatorName: '',
      operatorFields: [
        {
          key : "name",
          label : this.$t('stats.operators.name'),
          sortable: true
        },
        {
          key : "category",
          label : this.$t('stats.operators.category'),
          sortable: true
        },
        {
          key: "kills",
          label: this.$t('stats.operators.kills'),
          sortable: true
        },
        {
          key: "deaths",
          label: this.$t('stats.operators.deaths'),
          sortable: true
        },
        {
          key: "kd",
          label: this.$t('stats.operators.kd'),
          sortable: true
        },
        {
          key: "won-rounds",
          label: this.$t('stats.operators.won-rounds'),
          sortable: true
        },
        {
          key: "lost-rounds",
          label: this.$t('stats.operators.lost-rounds'),
          sortable: true
        },
        {
          key: "wl",
          label: this.$t('stats.operators.wl'),
          sortable: true
        },
        {
          key: "headshots",
          label: this.$t('stats.operators.headshots'),
          sortable: true
        },
        {
          key: "melee_kills",
          label: this.$t('stats.operators.melee-kills'),
          sortable: true
        },
        {
          key: "playtime",
          label: this.$t('stats.operators.playtime'),
          sortable: true
        }
      ],
      options: [{
        value: "all",
        text: this.$t('stats.operators.all')
      }, {
        value: "casual",
        text: this.$t('stats.operators.casual')
      }, {
        value: "ranked",
        text: this.$t('stats.operators.ranked')
      }, {
        value: "unranked",
        text: this.$t('stats.operators.unranked')
      }],
      operatorTypeOptions: [
        {
          value: "",
          text: this.$t('stats.operators.all-categories')
        },
        {
          value: this.$t('stats.operators.attacker'),
          text: this.$t('stats.operators.attacker')
        },
        {
          value: this.$t('stats.operators.defender'),
          text: this.$t('stats.operators.defender')
        }
      ]
    }
  },
  computed: {
    ...mapState("LanguageState",["language"]),
    isBusy() {
      return !this.data.operators["all"]
    },
    operatorItems(){
      if (!this.data.operators[this.selected])
        return []
      let ret = []
      for (let operator of this.data.operators[this.selected].teamRoles.attacker) {
        let category = this.$t('stats.operators.attacker')
        if (category.indexOf(this.operatorTypeSelected) !== -1 && operator.statsDetail.toUpperCase().indexOf(this.operatorName.toUpperCase().trim()) !== -1)
          ret.push({
            name: operator.statsDetail,
            category: category,
            kills: operator.kills,
            deaths: operator.death,
            kd: operator.killDeathRatio.value.toFixed(2),
            "won-rounds": operator.matchesWon,
            "lost-rounds": operator.matchesLost,
            wl: (operator.matchesLost !== 0 ? operator.matchesWon / operator.matchesLost : 0).toFixed(2),
            headshots: operator.headshots,
            melee_kills: operator.meleeKills,
            playtime: parseInt((operator.minutesPlayed / 60).toString()) + this.$t('stats.operators.hour') + operator.minutesPlayed % 60 + this.$t('stats.operators.minute')
          })
      }
      for (let operator of this.data.operators[this.selected].teamRoles.defender) {
        let category = this.$t('stats.operators.defender')
        if (category.indexOf(this.operatorTypeSelected) !== -1 && operator.statsDetail.toUpperCase().indexOf(this.operatorName.toUpperCase().trim()) !== -1)
          ret.push({
            name: operator.statsDetail,
            category: category,
            kills: operator.kills,
            deaths: operator.death,
            kd: operator.killDeathRatio.value.toFixed(2),
            "won-rounds": operator.matchesWon,
            "lost-rounds": operator.matchesLost,
            wl: (operator.matchesLost !== 0 ? operator.matchesWon / operator.matchesLost : 0).toFixed(2),
            headshots: operator.headshots,
            melee_kills: operator.meleeKills,
            playtime: parseInt((operator.minutesPlayed / 60).toString()) + this.$t('stats.operators.hour') + operator.minutesPlayed % 60 + this.$t('stats.operators.minute')
          })
      }
      return ret
    },
  },
  watch:{
    language() {
      this.updateFields()
    }
  },
  methods: {
    requireAvatar(name) {
      try {
        return require(`../assets/images/operators/${name}.png`)
      } catch (e) {
        return require(`../assets/images/operators/no class.png`)
      }
    },
    updateFields() {
      this.operatorFields =  [
        {
          key : "name",
          label : this.$t('stats.operators.name'),
          sortable: true
        },
        {
          key : "category",
          label : this.$t('stats.operators.category'),
          sortable: true
        },
        {
          key: "kills",
          label: this.$t('stats.operators.kills'),
          sortable: true
        },
        {
          key: "deaths",
          label: this.$t('stats.operators.deaths'),
          sortable: true
        },
        {
          key: "kd",
          label: this.$t('stats.operators.kd'),
          sortable: true
        },
        {
          key: "wins",
          label: this.$t('stats.operators.wins'),
          sortable: true
        },
        {
          key: "losses",
          label: this.$t('stats.operators.losses'),
          sortable: true
        },
        {
          key: "wl",
          label: this.$t('stats.operators.wl'),
          sortable: true
        },
        {
          key: "headshots",
          label: this.$t('stats.operators.headshots'),
          sortable: true
        },
        {
          key: "melee_kills",
          label: this.$t('stats.operators.melee-kills'),
          sortable: true
        },
        {
          key: "playtime",
          label: this.$t('stats.operators.playtime'),
          sortable: true
        }
      ]
      this.options = [{
        value: "all",
        text: this.$t('stats.operators.all')
      }, {
        value: "casual",
        text: this.$t('stats.operators.casual')
      }, {
        value: "ranked",
        text: this.$t('stats.operators.ranked')
      }, {
        value: "unranked",
        text: this.$t('stats.operators.unranked')
      }]
      this.operatorTypeOptions = [
        {
          value: "",
          text: this.$t('stats.operators.all-categories')
        },
        {
          value: this.$t('stats.operators.attacker'),
          text: this.$t('stats.operators.attacker')
        },
        {
          value: this.$t('stats.operators.defender'),
          text: this.$t('stats.operators.defender')
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
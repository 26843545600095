<template>
  <div>
    <b-row class="justify-content-center">
      <b-col cols="auto">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
              v-model="weaponTypeSelected"
              :options="weaponTypeOptions"
              :aria-describedby="ariaDescribedby"
              class="m-2"
              button-variant="dark"
              buttons
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col cols="auto">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
              v-model="selected"
              :options="options"
              :aria-describedby="ariaDescribedby"
              class="m-2"
              button-variant="dark"
              buttons
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col>
        <b-table show-empty :empty-text="$t('stats.weapons.empty')" :filter="weaponTypeSelected" :filter-included-fields="['category']" table-variant="dark" class="mt-3 ranking-table" hover :items="items" :fields="categoryFields" responsive :busy="isBusy">
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner> <strong>Loading...</strong>
            </div>
          </template>

        </b-table>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col>
        <b-table show-empty :empty-text="$t('stats.weapons.empty')" table-variant="dark" class="mt-3 ranking-table" hover :items="weaponItems" :fields="weaponFields" responsive :busy="isBusy">

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner> <strong>Loading...</strong>
            </div>
          </template>

        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Weapons",
  props:  ['data'],
  data() {
    return {
      weaponName: '',
      needUpdate: false,
      categoryFields: [{
        key : "category",
        label : this.$t('stats.weapons.category'),
        sortable: true
      },{
        key: "kills",
        label: this.$t('stats.weapons.kills'),
        sortable: true
      },{
        key: "lost_rounds",
        label: this.$t('stats.weapons.lost-rounds'),
        sortable: true
      },{
        key: "won_rounds",
        label: this.$t('stats.weapons.won-rounds'),
        sortable: true
      },{
        key: "headshots",
        label: this.$t('stats.weapons.headshots'),
        sortable: true
      },{
        key: "headshot_percentage",
        label: this.$t('stats.weapons.headshot-percentage'),
        sortable: true
      },{
        key: "chosen_times",
        label: this.$t('stats.weapons.chosen-times'),
        sortable: true
      }],
      weaponFields: [{
        key : "category",
        label : this.$t('stats.weapons.category'),
        sortable: true
        }, {
          key : "name",
          label : this.$t('stats.weapons.name'),
          sortable: true
        },{
          key: "kills",
          label: this.$t('stats.weapons.kills'),
          sortable: true
        },{
          key: "lost_rounds",
          label: this.$t('stats.weapons.lost-rounds'),
          sortable: true
        },{
          key: "won_rounds",
          label: this.$t('stats.weapons.won-rounds'),
          sortable: true
        },{
          key: "headshots",
          label: this.$t('stats.weapons.headshots'),
          sortable: true
        },{
          key: "headshot_percentage",
          label: this.$t('stats.weapons.headshot-percentage'),
          sortable: true
        },{
          key: "chosen_times",
          label: this.$t('stats.weapons.chosen-times'),
          sortable: true
        }
      ],
      options: [{
        value: "all",
        text: this.$t('stats.maps.all')
      }, {
        value: "casual",
        text: this.$t('stats.maps.casual')
      }, {
        value: "ranked",
        text: this.$t('stats.maps.ranked')
      }, {
        value: "unranked",
        text: this.$t('stats.maps.unranked')
      }],
      weaponTypeOptions: [{
        value: "",
        text: this.$t('stats.weapons.all-categories')
      },
        {
          value: this.$t('stats.weapons.assault-rifles'),
          text: this.$t('stats.weapons.assault-rifles')
        },
        {
          value : this.$t('stats.weapons.submachine-guns'),
          text: this.$t('stats.weapons.submachine-guns')
        },
        {
          value: this.$t('stats.weapons.light-machine-guns'),
          text: this.$t('stats.weapons.light-machine-guns')
        },
        {
          value: this.$t('stats.weapons.marksman-rifles'),
          text: this.$t('stats.weapons.marksman-rifles')
        },
        {
          value: this.$t('stats.weapons.pistols'),
          text: this.$t('stats.weapons.pistols')
        },
        {
          value: this.$t('stats.weapons.shotguns'),
          text: this.$t('stats.weapons.shotguns')
        },
        {
          value: this.$t('stats.weapons.machine-pistol'),
          text: this.$t('stats.weapons.machine-pistol')
        },
        {
          value: this.$t('stats.weapons.hand-cannon'),
          text: this.$t('stats.weapons.hand-cannon')
        },
        {
          value: this.$t('stats.weapons.shield'),
          text: this.$t('stats.weapons.shield')
        },
        {
          value: this.$t('stats.weapons.ops-ability'),
          text: this.$t('stats.weapons.ops-ability')
        },
        {
          value: this.$t('stats.weapons.gadget'),
          text: this.$t('stats.weapons.gadget')
        }
      ],
      selected: "all",
      weaponTypeSelected: ""
    }
  },
  computed: {
    ...mapState("LanguageState",["language"]),
    isBusy() {
      return !this.data.weapons["all"]
    },
    items() {
      if (!this.data.weapons[this.selected])
        return []
      let weapons = this.data.weapons[this.selected].teamRoles.all.weaponSlots
      let temp = [...weapons.primaryWeapons.weaponTypes,...weapons.secondaryWeapons.weaponTypes]
      let ret = []
      let map = new Map()
      for (let weaponType of temp) {
        let detail = map.get(weaponType.weaponType)
        if (detail === undefined)
          detail = {
            kills : 0,
            headshots: 0,
            lost_rounds: 0,
            won_rounds: 0,
            chosen_times: 0
          }
        for (let weapon of weaponType.weapons) {
          detail.kills += weapon.kills
          detail.headshots += weapon.headshots
          detail.lost_rounds += weapon.roundsLost
          detail.won_rounds += weapon.roundsWon
          detail.chosen_times += weapon.roundsPlayed
        }
        map.set(weaponType.weaponType,detail)
      }
      map.forEach((value, key) => {
        ret.push({
          category: this.$t('stats.weapons.' + key.toLowerCase().replaceAll(' ','-')),
          ...value,
          headshot_percentage : value.kills !== 0 ? (value.headshots / value.kills).toFixed(2) : 0
        })
      })
      return ret
    },
    weaponItems(){
      if (!this.data.weapons[this.selected])
        return []
      let weapons = this.data.weapons[this.selected].teamRoles.all.weaponSlots
      let temp = [...weapons.primaryWeapons.weaponTypes,...weapons.secondaryWeapons.weaponTypes]
      let ret = []
      let map = new Map()
      for (let weaponType of temp) {
        for (let weapon of weaponType.weapons) {
          let detail = map.get(weapon.weaponName)
          if (detail === undefined)
            detail = {
              category: this.$t('stats.weapons.' + weaponType.weaponType.toLowerCase().replaceAll(' ','-')),
              kills : 0,
              headshots: 0,
              lost_rounds: 0,
              won_rounds: 0,
              chosen_times: 0
            }
          detail.kills += weapon.kills
          detail.headshots += weapon.headshots
          detail.lost_rounds += weapon.roundsLost
          detail.won_rounds += weapon.roundsWon
          detail.chosen_times += weapon.roundsPlayed
          map.set(weapon.weaponName,detail)
        }
      }
      map.forEach((value, key) => {
        if (value.category.indexOf(this.weaponTypeSelected) !== -1 && key.toUpperCase().indexOf(this.weaponName.toUpperCase().trim()) !== -1)
          ret.push({
            name: key,
            ...value,
            headshot_percentage : value.kills !== 0 ? (value.headshots / value.kills).toFixed(2) : (0).toFixed(2)
          })
      })
      return ret
    },
  },
  watch:{
    language() {
      this.updateFields()
    }
  },
  methods: {
    updateFields() {
      this.options = [{
        value: "all",
        text: this.$t('stats.maps.all')
      }, {
        value: "casual",
        text: this.$t('stats.maps.casual')
      }, {
        value: "ranked",
        text: this.$t('stats.maps.ranked')
      }, {
        value: "unranked",
        text: this.$t('stats.maps.unranked')
      }]
      this.weaponTypeOptions = [{
        value: "",
        text: this.$t('stats.weapons.all-categories')
      },
        {
          value: this.$t('stats.weapons.assault-rifles'),
          text: this.$t('stats.weapons.assault-rifles')
        },
        {
          value : this.$t('stats.weapons.submachine-guns'),
          text: this.$t('stats.weapons.submachine-guns')
        },
        {
          value: this.$t('stats.weapons.light-machine-guns'),
          text: this.$t('stats.weapons.light-machine-guns')
        },
        {
          value: this.$t('stats.weapons.marksman-rifles'),
          text: this.$t('stats.weapons.marksman-rifles')
        },
        {
          value: this.$t('stats.weapons.pistols'),
          text: this.$t('stats.weapons.pistols')
        },
        {
          value: this.$t('stats.weapons.shotguns'),
          text: this.$t('stats.weapons.shotguns')
        },
        {
          value: this.$t('stats.weapons.machine-pistol'),
          text: this.$t('stats.weapons.machine-pistol')
        },
        {
          value: this.$t('stats.weapons.hand-cannon'),
          text: this.$t('stats.weapons.hand-cannon')
        },
        {
          value: this.$t('stats.weapons.shield'),
          text: this.$t('stats.weapons.shield')
        },
        {
          value: this.$t('stats.weapons.ops-ability'),
          text: this.$t('stats.weapons.ops-ability')
        },
        {
          value: this.$t('stats.weapons.gadget'),
          text: this.$t('stats.weapons.gadget')
        }
      ]
      this.categoryFields = [{
        key : "category",
        label : this.$t('stats.weapons.category'),
        sortable: true
      },{
        key: "kills",
        label: this.$t('stats.weapons.kills'),
        sortable: true
      },{
        key: "lost_rounds",
        label: this.$t('stats.weapons.lost-rounds'),
        sortable: true
      },{
        key: "won_rounds",
        label: this.$t('stats.weapons.won-rounds'),
        sortable: true
      },{
        key: "headshots",
        label: this.$t('stats.weapons.headshots'),
        sortable: true
      },{
        key: "headshot_percentage",
        label: this.$t('stats.weapons.headshot-percentage'),
        sortable: true
      },{
        key: "chosen_times",
        label: this.$t('stats.weapons.chosen-times'),
        sortable: true
      }]
      this.weaponFields = [{
        key : "category",
        label : this.$t('stats.weapons.category'),
        sortable: true
      }, {
        key : "name",
        label : this.$t('stats.weapons.name'),
        sortable: true
      },{
        key: "kills",
        label: this.$t('stats.weapons.kills'),
        sortable: true
      },{
        key: "lost_rounds",
        label: this.$t('stats.weapons.lost-rounds'),
        sortable: true
      },{
        key: "won_rounds",
        label: this.$t('stats.weapons.won-rounds'),
        sortable: true
      },{
        key: "headshots",
        label: this.$t('stats.weapons.headshots'),
        sortable: true
      },{
        key: "headshot_percentage",
        label: this.$t('stats.weapons.headshot-percentage'),
        sortable: true
      },{
        key: "chosen_times",
        label: this.$t('stats.weapons.chosen-times'),
        sortable: true
      }
      ]
    }

  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
<template>
  <b-row class="justify-content-center">
    <b-col>
      <slot name="copyright">
      </slot>
    </b-col>
    <b-col class="text-right" sm="auto">
      <b-row>
        <b-col>
          <span @click="toICP()" class="beian">{{icp}}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div @click="toBeian()" class="beian">
            <b-img src="../assets/images/beian.png"></b-img>
            {{beian}}
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "Beian",
  props: ['beian','icp','recordcode'],
  methods: {
    toICP() {
      window.open('http://beian.miit.gov.cn');
    },
    toBeian() {
      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' + this.recordcode);
    }
  }
}
</script>

<style scoped>
.beian:hover {
  cursor: pointer;
  color: #343a40;
}
</style>
<template>

  <div>
    <b-row class="justify-content-center">
      <b-col>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
              v-model="selected"
              :options="options"
              :aria-describedby="ariaDescribedby"
              class="m-2"
              button-variant="dark"
              buttons
              size="lg"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="auto">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
              v-model="mapTypeSelected"
              :options="mapTypeOptions"
              :aria-describedby="ariaDescribedby"
              class="m-2"
              button-variant="dark"
              buttons
              size="lg"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col>
        <b-table show-empty :empty-text="$t('stats.maps.empty')" :filter="{category: mapTypeSelected,name: mapName}" :filter-included-fields="['category','name']"  table-variant="dark" class="mt-3 ranking-table" hover :items="mapItems" :fields="mapFields" responsive :busy="isBusy">

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner> <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(name)="data">
            {{data.value}}
          </template>

        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Maps",
  props: ['data'],
  data(){
    return {
      mapName: '',
      selected: "all",
      mapTypeSelected: '',
      mapTypeOptions: [
        {
          value: "",
          text: this.$t('stats.maps.all-categories')
        },
        {
          value: this.$t('stats.maps.attacker'),
          text: this.$t('stats.maps.attacker')
        },
        {
          value: this.$t('stats.maps.defender'),
          text: this.$t('stats.maps.defender')
        }
      ],
      options: [{
        value: "all",
        text: this.$t('stats.maps.all')
      }, {
        value: "casual",
        text: this.$t('stats.maps.casual')
      }, {
        value: "ranked",
        text: this.$t('stats.maps.ranked')
      }, {
        value: "unranked",
        text: this.$t('stats.maps.unranked')
      }],
      mapFields: [
        {
          key : "name",
          label : this.$t('stats.maps.name'),
          sortable: true
        },
        {
          key : "category",
          label : this.$t('stats.maps.category'),
          sortable: true
        },
        {
          key: "kills",
          label: this.$t('stats.maps.kills'),
          sortable: true
        },
        {
          key: "deaths",
          label: this.$t('stats.maps.deaths'),
          sortable: true
        },
        {
          key: "kd",
          label: this.$t('stats.maps.kd'),
          sortable: true
        },
        {
          key: "wins",
          label: this.$t('stats.maps.wins'),
          sortable: true
        },
        {
          key: "losses",
          label: this.$t('stats.maps.losses'),
          sortable: true
        },
        {
          key: "wl",
          label: this.$t('stats.maps.wl'),
          sortable: true
        },
        {
          key: "headshots",
          label: this.$t('stats.maps.headshots'),
          sortable: true
        },
        {
          key: "melee_kills",
          label: this.$t('stats.maps.melee-kills'),
          sortable: true
        },
        {
          key: "playtime",
          label: this.$t('stats.maps.playtime'),
          sortable: true
        }
      ]
    }
  },
  computed: {
    ...mapState("LanguageState",["language"]),
    isBusy(){ return  !this.data.maps["all"]},
    mapItems(){
      if (!this.data.maps[this.selected])
        return []
      let ret = []
      for (let map of this.data.maps[this.selected].teamRoles.attacker) {
        let category = this.$t('stats.maps.attacker')
        if (category.indexOf(this.mapTypeSelected) !== -1 && map.statsDetail.toUpperCase().indexOf(this.mapName.toUpperCase().trim()) !== -1)
          ret.push({
            name: this.$t('stats.map.' + map.statsDetail),
            category: category,
            kills: map.kills,
            deaths: map.death,
            kd: map.killDeathRatio.value.toFixed(2),
            wins: map.matchesWon,
            losses: map.matchesLost,
            wl: (map.matchesLost !== 0 ? map.matchesWon / map.matchesLost : 0).toFixed(2),
            headshots: map.headshots,
            melee_kills: map.meleeKills,
            playtime: parseInt((map.minutesPlayed/60).toString()) + this.$t('stats.maps.hour') + map.minutesPlayed % 60 + this.$t('stats.maps.minute')
          })
      }
      for (let map of this.data.maps[this.selected].teamRoles.defender) {
        let category = this.$t('stats.maps.defender')
        if (category.indexOf(this.mapTypeSelected) !== -1 && map.statsDetail.toUpperCase().indexOf(this.mapName.toUpperCase().trim()) !== -1)
          ret.push({
            name: this.$t('stats.map.' + map.statsDetail),
            category: category,
            kills: map.kills,
            deaths: map.death,
            kd: map.killDeathRatio.value.toFixed(2),
            wins: map.matchesWon,
            losses: map.matchesLost,
            wl: (map.matchesLost !== 0 ? map.matchesWon / map.matchesLost : 0).toFixed(2),
            headshots: map.headshots,
            melee_kills: map.meleeKills,
            playtime: parseInt((map.minutesPlayed/60).toString()) + this.$t('stats.maps.hour') + map.minutesPlayed % 60 + this.$t('stats.maps.minute')
          })
      }
      return ret
    }
  },
  methods: {
    updateFields() {
      this.mapTypeOptions = [
        {
          value: "",
          text: this.$t('stats.maps.all-categories')
        },
        {
          value: this.$t('stats.maps.attacker'),
          text: this.$t('stats.maps.attacker')
        },
        {
          value: this.$t('stats.maps.defender'),
          text: this.$t('stats.maps.defender')
        }
      ]
      this.mapFields = [
        {
          key : "name",
          label : this.$t('stats.maps.name'),
          sortable: true
        },
        {
          key : "category",
          label : this.$t('stats.maps.category'),
          sortable: true
        },
        {
          key: "kills",
          label: this.$t('stats.maps.kills'),
          sortable: true
        },
        {
          key: "deaths",
          label: this.$t('stats.maps.deaths'),
          sortable: true
        },
        {
          key: "kd",
          label: this.$t('stats.maps.kd'),
          sortable: true
        },
        {
          key: "wins",
          label: this.$t('stats.maps.wins'),
          sortable: true
        },
        {
          key: "losses",
          label: this.$t('stats.maps.losses'),
          sortable: true
        },
        {
          key: "wl",
          label: this.$t('stats.maps.wl'),
          sortable: true
        },
        {
          key: "headshots",
          label: this.$t('stats.maps.headshots'),
          sortable: true
        },
        {
          key: "melee_kills",
          label: this.$t('stats.maps.melee-kills'),
          sortable: true
        },
        {
          key: "playtime",
          label: this.$t('stats.maps.playtime'),
          sortable: true
        }
      ]
      this.options = [{
        value: "all",
        text: this.$t('stats.maps.all')
      }, {
        value: "casual",
        text: this.$t('stats.maps.casual')
      }, {
        value: "ranked",
        text: this.$t('stats.maps.ranked')
      }, {
        value: "unranked",
        text: this.$t('stats.maps.unranked')
      }]
    }
  },
  watch:{
    language() {
      this.updateFields()
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <b-media right-align vertical-align="center">
      <template #aside>
        <b-img height="75" width="75" :src="rankImage"></b-img>
      </template>
      <h5>{{title}}</h5>
      <p>
        <span class="text-white-50">
        - {{$t('stats.seasons.mmr')}}
        </span>
        <span class="text-info">
        {{mmr}}
        </span>
      </p>
    </b-media>
  </div>
</template>

<script>

export default {
  name: "SeasonAbstract",
  props: ['season','namespace'],
  computed: {
    mmr(){
      if (!this.season.summary)
        return ''
      return this.season.summary[0].boards_player_skill_records[0].players_skill_records[0].mmr
    },
    title() {
      return this.$t('stats.seasons.' + this.season.all.seasonYear + this.season.all.seasonNumber)
    },
    rankImage() {
      if (!this.season.summary)
        return ''
      return require(`../assets/images/ranks/${this.season.summary[0].boards_player_skill_records[0].players_skill_records[0].rank}.png`)
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <b-navbar toggleable="lg" type="dark" >
    <b-navbar-brand tag="h1" class="mb-0 navbar-brand" @click="$router.replace({name: 'Home'})">
      {{$t('navbar.title')}}
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item @click="$router.replace({name:'Home'})" :disabled="isHome" :active="isHome">{{$t('navbar.index')}}</b-nav-item>
        <b-nav-item @click="$router.replace({name:'Ranking'})" :disabled="isRanking" :active="isRanking">{{$t('navbar.ranking')}}</b-nav-item>
        <b-nav-item @click="$router.replace({name:'Status'})" :disabled="isStatus" :active="isStatus">{{$t('navbar.status')}}</b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <b-input-group >
          <b-form-input ref="nav-search-input" id="nav-search-input" @submit.prevent @keydown.enter.prevent="search" size="sm" class="search-input" type="text" name="username" :placeholder="$t('navbar.search-placeholder')" v-model="keyword"></b-form-input>
          <b-popover variant="danger" placement="left" :show.sync="isInvalidUsername" target="nav-search-input" triggers="" :title="$t('navbar.invalid-username.title')">
            <strong>{{$t('navbar.invalid-username.description')}}</strong>
          </b-popover>
          <b-input-group-append>
            <b-form-radio-group
                size="sm"
                id="btn-radios-1"
                v-model="selectedPlatform"
                :options="platforms"
                name="radios-btn-default"
                buttons
            ></b-form-radio-group>
          </b-input-group-append>
          </b-input-group>
        </b-nav-form>

        <b-nav-item-dropdown :text="$t('navbar.language')" right>
          <b-dropdown-item v-for="lang in languages" :key="lang.value" :disabled="language === lang.value" @click="changeLanguage(lang)">{{lang.text}}</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import {mapState,mapGetters,mapMutations} from 'vuex'
export default {
  name: "NavigationBar",
  data() {
    return {
      selectedPlatform: '',
      isInvalidUsername: false,
      keyword: '',
      languages: [
        { text: "中文", value: "zh-CN" },
        { text: "English", value: "en-US" },
      ]
    };
  },
  computed: {
    ...mapState("LanguageState", ["language"]),
    ...mapState("PlatformState",['platform', 'platforms','lastPlatform']),
    ...mapState('SearchState',['searchKeyword','lastKeyword','isFound','isSearching']),
    ...mapGetters("NavigationState",["isHome","isRanking", "isSearch", "isStatus",])
  },
  methods: {
    ...mapMutations("LanguageState", ["SET_LANGUAGE"]),
    ...mapMutations('PlatformState',['SET_PLATFORM']),
    ...mapMutations('SearchState',['SET_SEARCH_KEYWORD']),
    changePlatform(platform){
      this.SET_PLATFORM(platform)
    },
    changeLanguage(lang) {
      this.SET_LANGUAGE(lang.value)
    },
    changeKeyword(keyword) {
      this.SET_SEARCH_KEYWORD(keyword)
    },
    search() {
      if (this.keyword === '') {
        this.isInvalidUsername = true
        return
      }
      if (this.lastKeyword === this.keyword && this.lastPlatform === this.selectedPlatform && this.isSearch)
        return
      this.$router.replace({
        name: 'Search',
        params: {
          platform: this.selectedPlatform,
          keyword: this.keyword
        }
      })
    },
    checkUsername() {
      if (this.keyword !== '')
        this.isInvalidUsername = false
    }
  },
  watch: {
    language(language) {
      this.$root.$i18n.locale = language
      localStorage.setItem("language", language)
    },
    selectedPlatform(newPlatform){
      this.changePlatform(newPlatform)
      if (this.isSearch && this.lastKeyword === this.keyword)
        this.search()
    },
    platform(newPlatform){
      this.selectedPlatform = newPlatform
    },
    keyword(newKeyword){
      this.changeKeyword(newKeyword)
      this.checkUsername()
    },
    searchKeyword(newKeyword){
      this.keyword = newKeyword
    },
    shouldFocus(shouldFocus) {
      if (shouldFocus) {
        this.$refs['nav-search-input'].focus()
      }
    }
  },
  mounted() {
    this.keyword = this.searchKeyword
    this.selectedPlatform = this.platform
  }
}
</script>

<style scoped>

@font-face{
  font-family: title font;
  src:url('../assets/fonts/title.woff2');
}

nav {
  font-family: "title font",serif;
}

.search-input {
  width: 200px;
}

.navbar-brand {
  cursor: pointer;
}
</style>
<template>
  <b-card no-body class="mb-1" bg-variant="secondary" border-variant="secondary">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block size="lg" v-b-toggle="id" variant="dark" class="float-left">
        <b-row>
          <template v-if="isAvailable">
            <b-col>
              {{title}}
              <b-img height="75" width="75" :src="rankImage"></b-img>
              <span class="text-white-50">
              - {{$t('stats.seasons.mmr')}}
              </span>
              <span class="text-info">
                {{mmr}}
              </span>
            </b-col>
            <b-col>
              <b-row>
                <b-col>
                  <span class="text-white-50">
                    - {{$t('stats.seasons.max-mmr')}}
                  </span>
                  <span class="text-info">
                    {{maxMMR}}
                  </span>
                  <span class="text-white-50">
                    - {{$t('stats.seasons.kills')}}
                  </span>
                  <span class="text-info">
                    {{kills}}
                  </span>
                  <span class="text-white-50">
                    - {{$t('stats.seasons.deaths')}}
                  </span>
                  <span class="text-info">
                    {{deaths}}
                  </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span class="text-white-50">
                    - {{$t('stats.seasons.won-rounds')}}
                  </span>
                  <span class="text-info">
                    {{wins}}
                  </span>
                  <span class="text-white-50">
                    - {{$t('stats.seasons.lost-rounds')}}
                  </span>
                  <span class="text-info">
                    {{losses}}
                  </span>
                  <span class="text-white-50">
                    - {{$t('stats.seasons.skill')}}
                  </span>
                  <span class="text-info">
                    {{skill}}
                  </span>
                </b-col>
              </b-row>
            </b-col>
          </template>
          <b-skeleton v-if="!isAvailable" animation="throb" type="button" width="100%"></b-skeleton>
        </b-row>
      </b-button>
    </b-card-header>
    <b-collapse :id="id" accordion="accordion" role="tabpanel">
      <b-card-body body-bg-variant="dark" body-text-variant="white">
        <b-table show-empty :empty-text="$t('stats.seasons.empty')" :fields="fields" :items="items" table-variant="dark" class="mt-3 ranking-table" hover responsive :busy="isBusy">
        </b-table>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "Season",
  props: ['season','namespace'],
  data(){
    return {
      isBusy: false,
      fields: [
        {
          key: 'type',
          label: this.$t('stats.seasons.type'),
          sortable: true,
        },
        {
          key: 'kills',
          label: this.$t('stats.seasons.kills'),
          sortable: true,
        },
        {
          key: 'deaths',
          label: this.$t('stats.seasons.deaths'),
          sortable: true,
        },
        {
          key: 'kd',
          label: this.$t('stats.seasons.kd'),
          sortable: true
        },
        {
          key: 'assists',
          label: this.$t('stats.seasons.assists'),
          sortable: true,
        },
        {
          key: 'headshots',
          label: this.$t('stats.seasons.headshots'),
          sortable: true,
        },
        {
          key: 'headshot-percentage',
          label: this.$t('stats.seasons.headshot-percentage'),
          sortable: true,
        },
        {
          key: 'melee-kills',
          label: this.$t('stats.seasons.melee-kills'),
          sortable: true,
        },
        {
          key: 'won-rounds',
          label: this.$t('stats.seasons.won-rounds'),
          sortable: true,
        },
        {
          key: 'lost-rounds',
          label: this.$t('stats.seasons.lost-rounds'),
          sortable: true,
        },
        {
          key: 'wl',
          label: this.$t('stats.seasons.wl'),
          sortable: true,
        }

      ]
    }
  },
  computed: {
    ...mapState("LanguageState",["language"]),
    mmr(){
      if (!this.season.summary)
        return ''
      return this.season.summary[0].boards_player_skill_records[0].players_skill_records[0].mmr
    },
    id() {
      return  this.season.all.seasonYear + this.season.all.seasonNumber + this.namespace
    },
    title() {
      return this.$t('stats.seasons.' + this.season.all.seasonYear + this.season.all.seasonNumber)
    },
    items() {
      let ret = []
      for (let value in this.season) {
        if (value === 'seasonId' || value === 'summary')
          continue
        let d = this.season[value]
        if (!d)
          continue
        ret.push({
          type: this.$t('stats.seasons.' + value),
          kills: d.kills,
          deaths: d.death,
          assists: d.assists,
          headshots: d.headshots,
          "headshot-percentage": (d.kills !== 0 ? d.headshots / d.kills : 0).toFixed(2),
          "melee-kills": d.meleeKills,
          "won-rounds": d.roundsWon,
          "lost-rounds": d.roundsLost,
          kd: (d.death !== 0 ? d.kills / d.death : 0).toFixed(2),
          wl: (d.roundsLost !== 0 ? d.roundsWon / d.roundsLost : 0).toFixed(2)
        })
      }
      return ret
    },
    rankImage() {
      if (!this.season.summary)
        return ''
      return require(`../assets/images/ranks/${this.season.summary[0].boards_player_skill_records[0].players_skill_records[0].rank}.png`)
    },
    previousMMR(){
      if (!this.season.summary)
        return ''
      return this.season.summary[0].boards_player_skill_records[0].players_skill_records[0].previous_rank_mmr
    },
    maxMMR() {
      if (!this.season.summary)
        return ''
      return this.season.summary[0].boards_player_skill_records[0].players_skill_records[0].max_mmr
    },
    deaths() {
      if (!this.season.summary)
        return ''
      return this.season.summary[0].boards_player_skill_records[0].players_skill_records[0].deaths
    },
    kills() {
      if (!this.season.summary)
        return ''
      return this.season.summary[0].boards_player_skill_records[0].players_skill_records[0].kills
    },
    wins() {
      if (!this.season.summary)
        return ''
      return this.season.summary[0].boards_player_skill_records[0].players_skill_records[0].wins
    },
    losses() {
      if (!this.season.summary)
        return ''
      return this.season.summary[0].boards_player_skill_records[0].players_skill_records[0].losses
    },
    skill() {
      if (!this.season.summary)
        return ''
      return this.season.summary[0].boards_player_skill_records[0].players_skill_records[0].skill_mean.toFixed(2) + ' ± ' + this.season.summary[0].boards_player_skill_records[0].players_skill_records[0].skill_stdev.toFixed(2)
    },
    isAvailable() {
      return this.season.summary !== undefined
    },
  },
  methods: {
    updateFields() {
      this.fields = [
        {
          key: 'type',
          label: this.$t('stats.seasons.type'),
          sortable: true,
        },
        {
          key: 'kills',
          label: this.$t('stats.seasons.kills'),
          sortable: true,
        },
        {
          key: 'deaths',
          label: this.$t('stats.seasons.deaths'),
          sortable: true,
        },
        {
          key: 'kd',
          label: this.$t('stats.seasons.kd'),
          sortable: true
        },
        {
          key: 'assists',
          label: this.$t('stats.seasons.assists'),
          sortable: true,
        },
        {
          key: 'headshots',
          label: this.$t('stats.seasons.headshots'),
          sortable: true,
        },
        {
          key: 'headshot-percentage',
          label: this.$t('stats.seasons.headshot-percentage'),
          sortable: true,
        },
        {
          key: 'melee-kills',
          label: this.$t('stats.seasons.melee-kills'),
          sortable: true,
        },
        {
          key: 'won-rounds',
          label: this.$t('stats.seasons.won-rounds'),
          sortable: true,
        },
        {
          key: 'lost-rounds',
          label: this.$t('stats.seasons.lost-rounds'),
          sortable: true,
        },
        {
          key: 'wl',
          label: this.$t('stats.seasons.wl'),
          sortable: true,
        }

      ]
    }
  },
  watch: {
    language() {
      this.updateFields()
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <b-row class="justify-content-center">
      <b-col cols="8">
        <hr>
        <h1 class="status-h display-4">
          {{$t("status.title")}}
          <b-spinner variant="white-50" type="grow" v-if="!isAvailable"></b-spinner>
          <template v-if="isAvailable">
            <b-icon-check v-if="allStatus === 3" variant="success"></b-icon-check>
            <b-icon-exclamation v-if="allStatus !== 3 && allStatus !== 0" variant="warning"></b-icon-exclamation>
            <b-icon-x v-if="allStatus === 0" variant="danger"></b-icon-x>
          </template>
        </h1>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col cols="8">
        <h3 class="status-h">{{$t("status.update-time")}}: {{this.updateTime}} {{this.$t('status.second')}} </h3>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col cols="8">
        <hr>
        <b-card-group v-if="isAvailable" deck>
          <b-card button v-for="platform of this.status" :key="platform.Platform">
            <p class="text-center">
              <strong class="big-platform" v-html="renderPlatform(platform.Platform)"></strong>
            </p>
            <p class="text-center">{{platform.Platform}}</p>
            <p class="text-center">
              <b-icon-check v-if="platform.Status === 'Online'" font-scale="1.5" variant="success"></b-icon-check>
              <b-icon-x v-else font-scale="1.5" variant="danger"></b-icon-x>
              {{platform.Status === 'Online' ? $t('status.online') : $t('status.offline')}}
            </p>
          </b-card>
        </b-card-group>
        <div class="text-center" v-if="!isAvailable">
          <b-spinner variant="white-50"></b-spinner>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex"
import axios from 'axios'

export default {
  name: "Status",
  data(){
    return {
      platformMap: {
        PC: "PC",
        PS4: "PSN",
        XBOXONE: "XBL",
      },
      status: [],
      updateTime: 60,
      isAvailable: false,
    }
  },
  computed: {
    ...mapState('PlatformState',['platforms']),
    allStatus() {
      let count = 0
      for (let platform of this.status)
        if (platform.Status === 'Online')
          count++
      return count
    }
  },
  methods: {
    ...mapMutations('NavigationState',['SET_PAGE']),
    renderPlatform(plat) {
      return this.platforms.find(platform => platform.value === this.platformMap[plat]).html
    }
  },
  mounted() {
    document.title = this.$t('status.title').toString()
    this.SET_PAGE('Status')
    axios.get('https://game-status-api.ubisoft.com/v1/instances?appIds=e3d5ea9e-50bd-43b7-88bf-39794f4e3d40,fb4cc4c9-2063-461d-a1e8-84a7d36525fc,4008612d-3baf-49e4-957a-33066726a7bc')
        .then(response=>{
          this.isAvailable = true
          this.status = response.data
        })
    this.interval = setInterval(()=>{
      if (this.updateTime)
        this.updateTime--
    },1000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  watch:{
    updateTime(newValue){
      if (newValue === 0) {
        axios.get('https://game-status-api.ubisoft.com/v1/instances?appIds=e3d5ea9e-50bd-43b7-88bf-39794f4e3d40,fb4cc4c9-2063-461d-a1e8-84a7d36525fc,4008612d-3baf-49e4-957a-33066726a7bc')
            .then(response=>{
              this.status = response.data
            })
        this.updateTime = 60
      }
    }
  }
}
</script>

<style>

h1.status-h {
  color: white;
}

h3.status-h {
  color: white;
}

.big-platform > .platform {
  height: 100px;
  width: 100px;
}

</style>
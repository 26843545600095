<template>
  <div>
    <b-row>
      <b-col cols="4">
        <b-card header-tag="header" header-bg-variant="dark" body-bg-variant="secondary" border-variant="secondary">
          <template #header>
            <h6 class="mb-0 text-white">{{ $t('stats.overview.top-operator') }}</h6>
          </template>
          <ul class="list-unstyled" v-if="isOperatorAvailable">
            <operator v-for="(operator,index) in topOperators" :key="index" :operator="operator"></operator>
          </ul>
          <ul class="list-unstyled" v-if="!isOperatorAvailable">
            <div v-for="i in 5" :key="i">
              <b-skeleton animation="throb" type="button" width="100%"></b-skeleton>
              <hr class="invisible"/>
            </div>
          </ul>
          <b-button block squared variant="dark" @click="$router.push({
              name: 'Operators'
            })">{{$t('stats.overview.view-all')}}</b-button>
        </b-card>
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <b-card header-tag="header" header-bg-variant="dark" body-bg-variant="secondary" border-variant="secondary">
              <template #header>
                <h6 class="mb-0 text-white">{{ $t('stats.overview.overall') }}</h6>
              </template>
              <b-row class="justify-content-center" v-if="isAvailable">
                <b-col cols="auto">
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.kd')}} -</span>  {{kd}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.kills')}} -</span>  {{kills}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.deaths')}} -</span>  {{deaths}}</h4></b-card-text>
                </b-col>
                <b-col cols="auto">
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.wl')}} -</span>  {{wl}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.won-rounds')}} -</span>  {{wins}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.lost-rounds')}} -</span>  {{losses}}</h4></b-card-text>
                </b-col>
                <b-col cols="auto">
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.playtime')}} -</span>  {{playtime}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.kills-per-round')}} -</span>  {{kpr}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.alive-per-match')}} -</span>  {{apm}}</h4></b-card-text>
                </b-col>
              </b-row>
              <b-row class="justify-content-center" v-if="!isAvailable">
                <b-col>
                  <b-skeleton type="button" animation="throb" width="100%"></b-skeleton>
                  <hr class="invisible"/>
                  <b-skeleton type="button" animation="throb" width="100%"></b-skeleton>
                  <hr class="invisible"/>
                  <b-skeleton type="button" animation="throb" width="100%"></b-skeleton>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card header-tag="header" header-bg-variant="dark" body-bg-variant="secondary" border-variant="secondary">
              <template #header>
                <h6 class="mb-0 text-white">{{ $t('stats.overview.overall-ranked') }}</h6>
              </template>
              <b-row class="justify-content-center" v-if="isAvailable">
                <b-col cols="auto">
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.kd')}} -</span>  {{kd_ranked}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.kills')}} -</span>  {{kills_ranked}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.deaths')}} -</span>  {{deaths_ranked}}</h4></b-card-text>
                </b-col>
                <b-col cols="auto">
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.wl')}} -</span>  {{wl_ranked}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.won-rounds')}} -</span>  {{wins_ranked}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.lost-rounds')}} -</span>  {{losses_ranked}}</h4></b-card-text>
                </b-col>
                <b-col cols="auto">
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.playtime')}} -</span>  {{playtime_ranked}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.kills-per-round')}} -</span>  {{kpr_ranked}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.alive-per-match')}} -</span>  {{apm_ranked}}</h4></b-card-text>
                </b-col>
              </b-row>
              <b-row class="justify-content-center" v-if="!isAvailable">
                <b-col>
                  <b-skeleton type="button" animation="throb" width="100%"></b-skeleton>
                  <hr class="invisible"/>
                  <b-skeleton type="button" animation="throb" width="100%"></b-skeleton>
                  <hr class="invisible"/>
                  <b-skeleton type="button" animation="throb" width="100%"></b-skeleton>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-card header-tag="header" header-bg-variant="dark" body-bg-variant="secondary" border-variant="secondary">
          <template #header>
            <h6 class="mb-0 text-white">{{ $t('stats.overview.top-season') }}</h6>
          </template>
          <ul class="list-unstyled" v-if="isSeasonAvailable">
            <season-abstract v-for="(season,index) in topSeasons" :key="index" :season="season" namespace="overview"></season-abstract>
          </ul>
          <ul class="list-unstyled" v-if="!isSeasonAvailable">
            <div v-for="i in 3" :key="i">
              <b-skeleton animation="throb" type="button" width="100%"></b-skeleton>
              <hr class="invisible"/>
            </div>
          </ul>
          <b-button block squared variant="dark" @click="$router.push({
            name: 'Seasons'
          })">{{$t('stats.overview.view-all')}}</b-button>
        </b-card>
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <b-card header-tag="header" header-bg-variant="dark" body-bg-variant="secondary" border-variant="secondary">
              <template #header>
                <h6 class="mb-0 text-white">{{ $t('stats.overview.overall-casual') }}</h6>
              </template>
              <b-row class="justify-content-center" v-if="isAvailable">
                <b-col cols="auto">
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.kd')}} -</span>  {{kd_casual}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.kills')}} -</span>  {{kills_casual}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.deaths')}} -</span>  {{deaths_casual}}</h4></b-card-text>
                </b-col>
                <b-col cols="auto">
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.wl')}} -</span>  {{wl_casual}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.won-rounds')}} -</span>  {{wins_casual}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.lost-rounds')}} -</span>  {{losses_casual}}</h4></b-card-text>
                </b-col>
                <b-col cols="auto">
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.playtime')}} -</span>  {{playtime_casual}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.kills-per-round')}} -</span>  {{kpr_casual}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.alive-per-match')}} -</span>  {{apm_casual}}</h4></b-card-text>
                </b-col>
              </b-row>
              <b-row class="justify-content-center" v-if="!isAvailable">
                <b-col>
                  <b-skeleton type="button" animation="throb" width="100%"></b-skeleton>
                  <hr class="invisible"/>
                  <b-skeleton type="button" animation="throb" width="100%"></b-skeleton>
                  <hr class="invisible"/>
                  <b-skeleton type="button" animation="throb" width="100%"></b-skeleton>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card header-tag="header" header-bg-variant="dark" body-bg-variant="secondary" border-variant="secondary">
              <template #header>
                <h6 class="mb-0 text-white">{{ $t('stats.overview.overall-unranked') }}</h6>
              </template>
              <b-row class="justify-content-center" v-if="isAvailable">
                <b-col cols="auto">
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.kd')}} -</span>  {{kd_unranked}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.kills')}} -</span>  {{kills_unranked}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.deaths')}} -</span>  {{deaths_unranked}}</h4></b-card-text>
                </b-col>
                <b-col cols="auto">
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.wl')}} -</span>  {{wl_unranked}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.won-rounds')}} -</span>  {{wins_unranked}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.lost-rounds')}} -</span>  {{losses_unranked}}</h4></b-card-text>
                </b-col>
                <b-col cols="auto">
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.playtime')}} -</span>  {{playtime_unranked}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.kills-per-round')}} -</span>  {{kpr_unranked}}</h4></b-card-text>
                  <b-card-text><h4><span class="text-white-50">{{$t('stats.overview.alive-per-match')}} -</span>  {{apm_unranked}}</h4></b-card-text>
                </b-col>
              </b-row>
              <b-row class="justify-content-center" v-if="!isAvailable">
                <b-col>
                  <b-skeleton type="button" animation="throb" width="100%"></b-skeleton>
                  <hr class="invisible"/>
                  <b-skeleton type="button" animation="throb" width="100%"></b-skeleton>
                  <hr class="invisible"/>
                  <b-skeleton type="button" animation="throb" width="100%"></b-skeleton>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Operator from './Operator'
import SeasonAbstract from './SeasonAbstract'
import axios from "axios";
import {mapState} from "vuex";
export default {
  name: "Overview",
  props: ['data'],
  data() {
    return {
      seasonsSummary: [],
    }
  },
  components: {
    Operator,SeasonAbstract
  },
  computed: {
    ...mapState('ApiState',['apiURL']),
    isAvailable() {
      return this.data.summary.all
    },
    isOperatorAvailable() {
      return this.data.operators.all
    },
    isSeasonAvailable() {
      return this.data.rank.summary.all
    },
    topOperators () {
      if (this.data.operators.all) {
        let temp = []
        for (let operator of this.data.operators.all.teamRoles.all) {
          temp.push({
            name: operator.statsDetail,
            "win-rate": operator.roundsPlayed !== 0 ? operator.roundsWon / operator.roundsPlayed : 0,
            kd: operator.kills !== 0 ? operator.death / operator.kills : 0,
            playtime: operator.minutesPlayed
          })
        }
        temp.sort((a, b) => {
          return b.playtime - a.playtime
        })
        return temp.slice(0, 5)
      }
      return []
    },
    topSeasons() {
      if (this.data.rank.summary.all) {
        let ret = []
        let seasonIds = ''
        for (let index in this.data.rank.summary.all.teamRoles.all){
          let season = parseInt(this.data.rank.summary.all.teamRoles.all[index].seasonNumber.substring(1))
          let year = parseInt(this.data.rank.summary.all.teamRoles.all[index].seasonYear.substring(1))
          let id = (year - 1) * 4 + season
          seasonIds = seasonIds + ',' + id
          ret.push({
            all: this.data.rank.summary.all.teamRoles.all[index],
            casual: this.data.rank.summary.casual ? this.data.rank.summary.casual.teamRoles.all[index] : undefined,
            ranked: this.data.rank.summary.ranked ? this.data.rank.summary.ranked.teamRoles.all[index] : undefined,
            unranked: this.data.rank.summary.unranked ? this.data.rank.summary.unranked.teamRoles.all[index] : undefined,
            seasonId: id,
            summary: undefined
          })
        }
        if (this.seasonsSummary.length === 0)
          this.fetchSummary(seasonIds)
        for (let season of this.seasonsSummary)
          for (let index in ret)
            if (ret[index].seasonId === season.season_id)
              ret[index].summary = season.regions_player_skill_records
        ret.sort((a,b) => {
          let tempA = a.all.seasonYear + a.all.seasonNumber
          let tempB = b.all.seasonYear + b.all.seasonNumber
          return tempA < tempB ? 1 : tempA > tempB ? -1 : 0
        })
        return ret.slice(0,3)
      }
      return []
    },
    kd() {
      if (!this.data.summary.all)
        return '0.00'
      return this.data.summary.all.teamRoles.all[0].killDeathRatio.value.toFixed(2)
    },
    kd_ranked() {
      if (!this.data.summary.ranked)
        return '0.00'
      return this.data.summary.ranked.teamRoles.all[0].killDeathRatio.value.toFixed(2)
    },
    kd_unranked() {
      if (!this.data.summary.unranked)
        return '0.00'
      return this.data.summary.unranked.teamRoles.all[0].killDeathRatio.value.toFixed(2)
    },
    kd_casual() {
      if (!this.data.summary.casual)
        return '0.00'
      return this.data.summary.casual.teamRoles.all[0].killDeathRatio.value.toFixed(2)
    },
    wl(){
      if (!this.data.summary.all)
        return '0.00'
      return this.data.summary.all.teamRoles.all[0].winLossRatio.toFixed(2)
    },
    wl_ranked(){
      if (!this.data.summary.ranked)
        return '0.00'
      return this.data.summary.ranked.teamRoles.all[0].winLossRatio.toFixed(2)
    },
    wl_unranked(){
      if (!this.data.summary.unranked)
        return '0.00'
      return this.data.summary.unranked.teamRoles.all[0].winLossRatio.toFixed(2)
    },
    wl_casual(){
      if (!this.data.summary.casual)
        return '0.00'
      return this.data.summary.casual.teamRoles.all[0].winLossRatio.toFixed(2)
    },
    kills() {
      if (!this.data.summary.all)
        return '0'
      return this.data.summary.all.teamRoles.all[0].kills
    },
    kills_ranked() {
      if (!this.data.summary.ranked)
        return '0'
      return this.data.summary.ranked.teamRoles.all[0].kills
    },
    kills_unranked() {
      if (!this.data.summary.unranked)
        return '0'
      return this.data.summary.unranked.teamRoles.all[0].kills
    },
    kills_casual() {
      if (!this.data.summary.casual)
        return '0'
      return this.data.summary.casual.teamRoles.all[0].kills
    },
    deaths() {
      if (!this.data.summary.all)
        return '0'
      return this.data.summary.all.teamRoles.all[0].death
    },
    deaths_ranked() {
      if (!this.data.summary.ranked)
        return '0'
      return this.data.summary.ranked.teamRoles.all[0].death
    },
    deaths_unranked() {
      if (!this.data.summary.unranked)
        return '0'
      return this.data.summary.unranked.teamRoles.all[0].death
    },
    deaths_casual() {
      if (!this.data.summary.casual)
        return '0'
      return this.data.summary.casual.teamRoles.all[0].death
    },
    wins() {
      if(!this.data.summary.all)
        return '0'
      return this.data.summary.all.teamRoles.all[0].roundsWon
    },
    wins_ranked() {
      if(!this.data.summary.ranked)
        return '0'
      return this.data.summary.ranked.teamRoles.all[0].roundsWon
    },
    wins_unranked() {
      if(!this.data.summary.unranked)
        return '0'
      return this.data.summary.unranked.teamRoles.all[0].roundsWon
    },
    wins_casual() {
      if(!this.data.summary.casual)
        return '0'
      return this.data.summary.casual.teamRoles.all[0].roundsWon
    },
    losses() {
      if(!this.data.summary.all)
        return '0'
      return this.data.summary.all.teamRoles.all[0].roundsLost
    },
    losses_ranked() {
      if(!this.data.summary.ranked)
        return '0'
      return this.data.summary.ranked.teamRoles.all[0].roundsLost
    },
    losses_unranked() {
      if(!this.data.summary.unranked)
        return '0'
      return this.data.summary.unranked.teamRoles.all[0].roundsLost
    },
    losses_casual() {
      if(!this.data.summary.casual)
        return '0'
      return this.data.summary.casual.teamRoles.all[0].roundsLost
    },
    playtime() {
      if(!this.data.summary.all)
        return '0'
      return parseInt((this.data.summary.all.teamRoles.all[0].minutesPlayed / 60).toString()) + this.$t('stats.overview.hour') + (this.data.summary.all.teamRoles.all[0].minutesPlayed % 60) + this.$t('stats.overview.minute')
    },
    playtime_ranked() {
      if(!this.data.summary.ranked)
        return '0'
      return parseInt((this.data.summary.ranked.teamRoles.all[0].minutesPlayed / 60).toString()) + this.$t('stats.overview.hour') + (this.data.summary.ranked.teamRoles.all[0].minutesPlayed % 60) + this.$t('stats.overview.minute')
    },
    playtime_unranked() {
      if(!this.data.summary.unranked)
        return '0'
      return parseInt((this.data.summary.unranked.teamRoles.all[0].minutesPlayed / 60).toString()) + this.$t('stats.overview.hour') + (this.data.summary.unranked.teamRoles.all[0].minutesPlayed % 60) + this.$t('stats.overview.minute')
    },
    playtime_casual() {
      if(!this.data.summary.casual)
        return '0'
      return parseInt((this.data.summary.casual.teamRoles.all[0].minutesPlayed / 60).toString()) + this.$t('stats.overview.hour') + (this.data.summary.casual.teamRoles.all[0].minutesPlayed % 60) + this.$t('stats.overview.minute')
    },
    kpr() {
      if(!this.data.summary.all)
        return '0'
      return this.data.summary.all.teamRoles.all[0].killsPerRound.value.toFixed(2)
    },
    kpr_ranked() {
      if(!this.data.summary.ranked)
        return '0'
      return this.data.summary.ranked.teamRoles.all[0].killsPerRound.value.toFixed(2)
    },
    kpr_unranked() {
      if(!this.data.summary.unranked)
        return '0'
      return this.data.summary.unranked.teamRoles.all[0].killsPerRound.value.toFixed(2)
    },
    kpr_casual() {
      if(!this.data.summary.casual)
        return '0'
      return this.data.summary.casual.teamRoles.all[0].killsPerRound.value.toFixed(2)
    },
    apm() {
      if(!this.data.summary.all)
        return '0'
      return this.data.summary.all.teamRoles.all[0].timeAlivePerMatch.toFixed(2) + this.$t('stats.overview.second')
    },
    apm_ranked() {
      if(!this.data.summary.ranked)
        return '0'
      return this.data.summary.ranked.teamRoles.all[0].timeAlivePerMatch.toFixed(2) + this.$t('stats.overview.second')
    },
    apm_unranked() {
      if(!this.data.summary.unranked)
        return '0'
      return this.data.summary.unranked.teamRoles.all[0].timeAlivePerMatch.toFixed(2) + this.$t('stats.overview.second')
    },
    apm_casual() {
      if(!this.data.summary.casual)
        return '0'
      return this.data.summary.casual.teamRoles.all[0].timeAlivePerMatch.toFixed(2) + this.$t('stats.overview.second')
    },
  },
  methods: {
    fetchSummary(seasonIds) {
      axios.post(`${this.apiURL}ubi/rank/record`,{
        uuid: this.data.profileUUID,
        platform: this.data.platform,
        seasonIds: seasonIds.length !== 0 ? seasonIds.substring(1) : ''
      }).then (
          response => {
            this.seasonsSummary = response.data.seasons_player_skill_records
          }
      )
    }
  }
}
</script>

<style scoped>

</style>
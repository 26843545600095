<template>
  <b-container>
    <hr>
    <b-row v-if="!isValid" class="justify-content-center">
      <b-col cols="8">
        <b-alert show variant="danger">
          <b-button-close @click="$router.push({
          name: 'Home'
          })" />
          <h4 class="alert-heading">{{$t('stats.error.title')}}</h4>
          <p>
            {{$t('stats.error.description')}}
          </p>
        </b-alert>
      </b-col>
    </b-row>
    <template v-if="isValid">
      <b-row>
        <b-col>
          <b-card bg-variant="secondary">
            <b-row>
              <b-col md="2">
                <b-avatar v-if="isOverviewAvailable" rounded :src="`https://ubisoft-avatars.akamaized.net/${uuid}/default_tall.png`" class="avatar"></b-avatar>
                <b-skeleton-img v-if="!isOverviewAvailable" aspect="1:1" animation="throb" class="avatar"></b-skeleton-img>
              </b-col>
              <b-col md="6">
                <b-row>
                  <b-col>
                    <h1 class="text-white" v-if="!isOverviewAvailable">
                      <b-skeleton animation="throb" width="100%"></b-skeleton>
                    </h1>
                    <h1 class="text-white" v-if="isOverviewAvailable">
                      <span class="username">{{name}}</span>
                      <span v-html="renderPlatform()"></span>
                    </h1>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-card bg-variant="secondary" border-variant="secondary">
                      <b-card-text v-if="isOverviewAvailable">
                        <span class="text-dark">
                          - {{$t('stats.overview.level')}}
                        </span>
                        <span class="text-white">
                          {{player.progression.level}}
                        </span>
                        <span class="text-dark">
                          - {{$t('stats.overview.kd')}}
                        </span>
                        <span class="text-white" id="tooltip-kd">
                          {{player.overview.deaths !== 0 ? (player.overview.kills/player.overview.deaths).toFixed(2) : (0).toFixed(2)}}
                        </span>
                        <b-tooltip target="tooltip-kd" triggers="hover">{{renderKD()}}</b-tooltip>
                        <span class="text-dark">
                          - {{$t('stats.overview.update-time.title')}}
                        </span>
                        <span class="text-white" id="tooltip-update-time">
                          {{this.renderUpdateTime()}}
                        </span>
                        <b-tooltip target="tooltip-update-time" triggers="hover" v-show="isShouldRenderUpdateTimeTooltip()">{{renderUpdateTime0()}}</b-tooltip>
                      </b-card-text>
                      <b-card-text v-if="!isOverviewAvailable">
                        <b-skeleton type="" animation="throb"></b-skeleton>
                      </b-card-text>
                    </b-card>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="4">
                <b-row>
                  <b-col cols="auto">
                    <b-skeleton-img v-if="!isOverviewAvailable" height="130px" width="130px" no-aspect animation="throb"></b-skeleton-img>
                    <b-img v-if="isOverviewAvailable" height="130" width="auto" :src="rankImage" ></b-img>
                  </b-col>
                  <b-col>
                    <b-row no-gutters>
                      <b-col>
                        <h4 v-if="isOverviewAvailable" class="text-black-50"><strong>{{renderRank()}}</strong></h4>
                        <b-skeleton animation="throb" v-if="!isOverviewAvailable"></b-skeleton>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col v-if="isOverviewAvailable">
                        <h6>
                           <span class="text-black-50">
                          -{{$t('stats.overview.mmr')}}
                        </span>
                          <span class="text-white">
                          {{player.overview.mmr}}
                        </span>
                          <span class="text-black-50">
                          -{{$t('stats.overview.wins')}}
                        </span>
                          <span class="text-white">
                          {{player.overview.wins}}
                        </span>
                          <span class="text-black-50">
                          -{{$t('stats.overview.losses')}}
                        </span>
                          <span class="text-white">
                          {{player.overview.losses}}
                        </span>
                        </h6>
                      </b-col>
                      <b-skeleton animation="throb" width="100%" v-if="!isOverviewAvailable"></b-skeleton>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <hr class="my-4 invisible">
      <b-row>
        <b-col sm="12">
          <b-card no-body bg-variant="secondary">
            <b-card-header header-tag="nav" header-bg-variant="">
              <b-nav card-header fill align="left" type="dark">
                <!-- <b-nav-item>'s with child routes. Note the trailing slash on the first <b-nav-item> -->
                <b-nav-item :to="{
                  name: 'Overview'
                }" exact exact-active-class="active bg-dark">{{$t('stats.overview.title')}}</b-nav-item>
                <b-nav-item :to="{
                  name: 'Seasons'
                }" exact exact-active-class="active bg-dark">{{$t('stats.seasons.title')}}</b-nav-item>
                <b-nav-item :to="{
                  name: 'Maps'
                }" exact exact-active-class="active bg-dark">{{$t('stats.maps.title')}}</b-nav-item>
                <b-nav-item :to="{
                  name: 'Operators'
                }" exact exact-active-class="active bg-dark">{{$t('stats.operators.title')}}</b-nav-item>
                <b-nav-item :to="{
                  name: 'Weapons'
                }" exact exact-active-class="active bg-dark">{{$t('stats.weapons.title')}}</b-nav-item>
              </b-nav>
            </b-card-header>

            <b-card-body>
              <!-- Child route gets rendered in <router-view> or <nuxt-child> -->
              <keep-alive>
                <router-view :data="player"></router-view>
              </keep-alive>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </template>


  </b-container>
</template>

<script>
import {mapMutations, mapState} from "vuex"
import axios from "axios"

export default {
  name: "Stats",
  data(){
    return {
      isOverviewAvailable: false,
      platformMap: {
        "PC": "PC",
        "PSN": "PS4",
        "XBL": "XONE"
      },
      name: '',
      uuid: '',
      platform: '',
      isValid: true,
      loading: true,
      player: {
        uuid: this.$route.params.uuid,
        platform: this.$route.params.platform,
        profileUUID: '',
        overview: {},
        maps: {},
        summary: {},
        operators: {},
        weapons: {},
        trend: {},
        progression: {},
        rank: {
          stats: {},
          summary: {}
        }
      }
    }
  },
  computed: {
    ...mapState('ApiState',['apiURL']),
    ...mapState('PlatformState',['platforms']),
    rankImage() {
      return this.player.overview.rank !== undefined ? require(`../assets/images/ranks/${this.player.overview.rank}.png`) : ""
    }
  },
  methods: {
    ...mapMutations('NavigationState',['SET_PAGE']),
    initStats() {
      this.isValid = true
      this.uuid = this.$route.params.uuid
      this.platform = this.$route.params.platform
      const user = {
        uuid: this.uuid,
        platform: this.platform
      }
      axios.post(`${this.apiURL}ubi/search`, user).then(
          response => {
            this.name = response.data.profiles[0].nameOnPlatform
            this.player.profileUUID = response.data.profiles[0].profileId
            user.uuid = response.data.profiles[0].profileId
            axios.post(`${this.apiURL}ubi/progression`, user).then(
                response => {
                  this.player.progression = response.data['player_profiles'][0]
                }).catch(() => {
              this.isValid = false
            })
            axios.post(`${this.apiURL}ubi/overview`,user).then(
                response => {
                  this.player.overview = response.data.players[Object.keys(response.data.players)[0]]
                  this.isOverviewAvailable = true
                }
            ).catch( () => {
              this.isValid = false
            })
            axios.post(`${this.apiURL}ubi/current/operators`,user).then(
                response => {
                  this.player.operators = response.data.platforms[this.platformMap[this.platform]].gameModes
                }
            ).catch( () => {
              this.isValid = false
            })
            axios.post(`${this.apiURL}ubi/current/maps`,user).then(
                response => {
                  this.player.maps = response.data.platforms[this.platformMap[this.platform]].gameModes
                }
            ).catch( () => {
              this.isValid = false
            })
            axios.post(`${this.apiURL}ubi/current/summary`,user).then(
                response => {
                  this.player.summary = response.data.platforms[this.platformMap[this.platform]].gameModes
                }
            ).catch( () => {
              this.isValid = false
            })
            axios.post(`${this.apiURL}ubi/current/trend`,user).then(
                response => {
                  this.player.trend = response.data.platforms[this.platformMap[this.platform]].gameModes
                }
            ).catch( () => {
              this.isValid = false
            })
            axios.post(`${this.apiURL}ubi/current/weapons`,user).then(
                response => {
                  this.player.weapons = response.data.platforms[this.platformMap[this.platform]].gameModes
                }
            ).catch( () => {
              this.isValid = false
            })
            axios.post(`${this.apiURL}ubi/rank/stats`,user).then(
                response => {
                  this.player.rank.stats = response.data.results[this.uuid]
                }
            ).catch( () => {
              this.isValid = false
            })
            axios.post(`${this.apiURL}ubi/rank/summary`,user).then(
                response => {
                  this.player.rank.summary = response.data.platforms[this.platformMap[this.platform]].gameModes
                }
            ).catch( () => {
              this.isValid = false
            })
        })
        .catch(() => {
            this.isValid = false
        })
    },
    renderPlatform() {
      return this.platforms.find(platform => platform.value === this.$route.params.platform).html
    },
    renderUpdateTime() {
      //todo remove it
      if (!this.player.overview.updateTime)
        return ""
      let updateTime = new Date(this.player.overview.updateTime)
      let offset = new Date().getTime() - updateTime.getTime()
      let seconds = Math.floor(offset / 1000)
      if (seconds < 10)
        return this.$t('stats.overview.update-time.just-now')
      else if (seconds < 60)
        return seconds + " " + this.$t('stats.overview.update-time.seconds-ago')
      else if (seconds < 3600)
        return Math.floor(seconds / 60) + " " + this.$t('stats.overview.update-time.minutes-ago')
      else if (seconds < 86400)
        return Math.floor(seconds / 3600) + " " + this.$t('stats.overview.update-time.hours-ago')
      else
        return this.renderUpdateTime0()
    },
    isShouldRenderUpdateTimeTooltip() {
      if (!this.player.overview.updateTime)
        return false
      let updateTime = new Date(this.player.overview.updateTime)
      let offset = new Date().getTime() - updateTime.getTime()
      let seconds = Math.floor(offset / 1000)
      return seconds < 86400;
    },
    renderUpdateTime0(){
      if (!this.player.overview.updateTime)
        return ""
      let updateTime = new Date(this.player.overview.updateTime)
      return this.$d(updateTime, "long")
    },
    renderKD() {
      if (!this.player.overview.kills || !this.player.overview.deaths)
        return ""
      return "- " + this.$t('stats.overview.kills') + " " + this.player.overview.kills + " - " + this.$t('stats.overview.deaths') + " " + this.player.overview.deaths
    },
    renderRank() {
      if (!this.player.overview.rank)
        return this.$t('stats.overview.ranks.unranked')
      if (this.player.overview.rank === 1)
        return this.$t('stats.overview.ranks.copper') + ' V'
      if (this.player.overview.rank === 2)
        return this.$t('stats.overview.ranks.copper') + ' IV'
      if (this.player.overview.rank === 3)
        return this.$t('stats.overview.ranks.copper') + ' III'
      if (this.player.overview.rank === 4)
        return this.$t('stats.overview.ranks.copper') + ' II'
      if (this.player.overview.rank === 5)
        return this.$t('stats.overview.ranks.copper') + ' I'
      if (this.player.overview.rank === 6)
        return this.$t('stats.overview.ranks.bronze') + ' V'
      if (this.player.overview.rank === 7)
        return this.$t('stats.overview.ranks.bronze') + ' IV'
      if (this.player.overview.rank === 8)
        return this.$t('stats.overview.ranks.bronze') + ' III'
      if (this.player.overview.rank === 9)
        return this.$t('stats.overview.ranks.bronze') + ' II'
      if (this.player.overview.rank === 10)
        return this.$t('stats.overview.ranks.bronze') + ' I'
      if (this.player.overview.rank === 11)
        return this.$t('stats.overview.ranks.silver') + ' V'
      if (this.player.overview.rank === 12)
        return this.$t('stats.overview.ranks.silver') + ' IV'
      if (this.player.overview.rank === 13)
        return this.$t('stats.overview.ranks.silver') + ' III'
      if (this.player.overview.rank === 14)
        return this.$t('stats.overview.ranks.silver') + ' II'
      if (this.player.overview.rank === 15)
        return this.$t('stats.overview.ranks.silver') + ' I'
      if (this.player.overview.rank === 16)
        return this.$t('stats.overview.ranks.gold') + ' III'
      if (this.player.overview.rank === 17)
        return this.$t('stats.overview.ranks.gold') + ' II'
      if (this.player.overview.rank === 18)
        return this.$t('stats.overview.ranks.gold') + ' I'
      if (this.player.overview.rank === 19)
        return this.$t('stats.overview.ranks.platinum') + ' III'
      if (this.player.overview.rank === 20)
        return this.$t('stats.overview.ranks.platinum') + ' II'
      if (this.player.overview.rank === 21)
        return this.$t('stats.overview.ranks.platinum') + ' I'
      if (this.player.overview.rank === 22)
        return this.$t('stats.overview.ranks.diamond') + ' III'
      if (this.player.overview.rank === 23)
        return this.$t('stats.overview.ranks.diamond') + ' II'
      if (this.player.overview.rank === 24)
        return this.$t('stats.overview.ranks.diamond') + ' I'
      if (this.player.overview.rank === 25)
        return this.$t('stats.overview.ranks.champions')
    }
  },
  watch: {
    isValid(){
      if (!this.isValid)
        document.title = this.$t('stats.error.title').toString()
    },
    name() {
      document.title = this.name + " " + this.$t('stats.on').toString() + " " + this.$route.params.platform
    }
  },
  mounted() {
    this.SET_PAGE(this.$route.name)
    this.initStats()
  },
  beforeRouteUpdate(to, from, next) {
    next()
    if (['Overview','Seasons','Matches','Operators','Weapons'].indexOf(from.name) === -1)
      this.initStats()
  },
}
</script>

<style scoped>
.avatar {
  width: 100%;
  height: auto;
  aspect-ratio: 1;
}

li > a {
  color: #fff;
}
.username {
  margin-right: 12px;
}

.text-gray {
  color: #868e96;
}

.rank-image {
  height: 100%;
  width: auto;
  aspect-ratio: 1;
}

</style>
import Vue from 'vue'
import { BootstrapVue, IconsPlugin,BootstrapVueIcons} from 'bootstrap-vue'


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.config.productionTip = false

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import App from './App'
import store from './store'
import i18n from  './i18n'
import router from './router'

const vm = new Vue({
  render: h => h(App),
  i18n,
  store,
  router
}).$mount('#app')

vm.$bus = vm;


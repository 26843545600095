import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home'
import Ranking from '../pages/Ranking'
import Search from "../pages/Search"
import Stats from "../pages/Stats"
import Status from "../pages/Status"
import Overview from "../components/Overview"
import Seasons from "../components/Seasons"
import Operators from "../components/Operators"
import Weapons from "../components/Weapons"
import Maps from "../components/Maps"

Vue.use(VueRouter)

export default new VueRouter({
    routes : [
        {
            name: 'Home',
            path: '/home',
            component: Home
        },
        {
            name: 'Ranking',
            path: '/ranking',
            component: Ranking
        },
        {
            name: "Default",
            path: "",
            redirect: "/home"
        },
        {
            name: "Search",
            path: "/search/:platform/:keyword",
            component: Search
        },
        {
            name: "Status",
            path: "/status",
            component: Status
        },
        {
            path: "/stats/:platform/:uuid",
            component: Stats,
            children:[
                {
                    name: "Overview",
                    path: "overview",
                    component: Overview
                },
                {
                    name: "Seasons",
                    path: "seasons",
                    component: Seasons
                },
                {
                    name: "Operators",
                    path: "operators",
                    component: Operators
                },
                {
                    name: "Weapons",
                    path: "weapons",
                    component: Weapons
                },
                {
                    name: "Maps",
                    path: "maps",
                    component: Maps
                },
                {
                    name: "StatsDefault",
                    path: "",
                    redirect: "overview"
                }
            ]
        },
        {
            name: "NotFound",
            path: "*",
            redirect: "/home"
        }
    ]
})
<template>
  <b-container fluid class="r-footer" :class="{
    'bottom-row': bottomRow,
    'fixed-bottom': fixedBottom,
  }">
    <b-row class="justify-content-center">
      <b-col cols="9">
        <beian icp="icp备案" recordcode="icp备案号" beian="公网安备号" >
          <template #copyright>
            <h5>Copyright © 2022 - 2022 R6Stats All rights reserved</h5>
            <p>{{$t('disclaimer')}}</p>
          </template>
        </beian>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Beian from '../components/Beian'

export default {
  name: "RFooter",
  data () {
    return {
      bottomRow: false,
      fixedBottom: false,
    }
  },
  components: {
    Beian
  },
  mounted() {
    setInterval(() => {
      this.bottomRow = window.screen.availHeight < document.body.scrollHeight
      this.fixedBottom = !this.bottomRow
    }, 100)
  }
}
</script>

<style scoped>
.r-footer {
  background-color: #6c757d;
  border-top: 25px solid #6c757d;
}
</style>
<template>
  <b-media tag="li">
    <template #aside>
      <b-img width="64" alt="placeholder" :src="require(`../assets/images/operators/${operator.name.toLowerCase()}.png`)"></b-img>
    </template>
    <h5 class="mt-0 mb-1">{{operator.name}}</h5>
    <p class="mb-0">
      <b-icon icon="clock" :id="'tooltip-playtime' + operator.name"></b-icon>
      <b-tooltip :target="'tooltip-playtime' + operator.name" triggers="hover">{{$t('stats.overview.playtime')}}</b-tooltip>
      {{parseInt((operator.playtime/60).toString()) + this.$t('stats.overview.hour') + operator.playtime % 60 + this.$t('stats.overview.minute')}}
      <b-icon icon="person-fill" :id="'tooltip-kd' + operator.name"></b-icon>
      <b-tooltip :target="'tooltip-kd' + operator.name" triggers="hover">{{$t('stats.overview.kd')}}</b-tooltip>
      {{operator.kd.toFixed(2)}}
      <b-icon icon="trophy-fill" :id="'tooltip-win-rate' + operator.name"></b-icon>
      <b-tooltip :target="'tooltip-win-rate' + operator.name" triggers="hover">{{$t('stats.overview.win-rate')}}</b-tooltip>
      {{(operator['win-rate'] * 100).toFixed(2) + '%'}}
    </p>
  </b-media>
</template>

<script>
export default {
  name: "Operator",
  props: ['operator'],
}
</script>

<style scoped>

</style>
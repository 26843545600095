<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col sm="3" >
        <b-img src="../assets/images/logo_nobak.png" class="logo"></b-img>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col sm="6" >
        <b-form-group>
          <b-input-group class="mt-3">
            <b-form-input id="search-input" autocomplete="off" autofocus @keydown.enter="search" :placeholder="$t('home.search-placeholder')" type="text" name="username" v-model="keyword"></b-form-input>
            <b-popover variant="danger" placement="left" :show.sync="isInvalidUsername" target="search-input" triggers="" :title="$t('home.invalid-username.title')">
              <strong>{{$t('home.invalid-username.description')}}</strong>
            </b-popover>
            <b-input-group-append>
              <b-form-radio-group
                  id="btn-radios-1"
                  v-model="selectedPlatform"
                  :options="platforms"
                  name="radios-btn-default"
                  buttons
              ></b-form-radio-group>
            </b-input-group-append>
          </b-input-group>
          <b-list-group>
            <b-list-group-item class="history" @click="
            $router.replace({
              name: 'Search',
              params: {
                platform: item.platform,
                keyword: item.name
        }})" v-for="item in history" :key="item.name + item.platform"><strong>{{item.name}}</strong> <span v-html="renderPlatform(item.platform)"></span></b-list-group-item>
          </b-list-group>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import  {mapState ,mapMutations, mapGetters} from "vuex"

export default {
  name: "Home",
  methods: {
    ...mapMutations('PlatformState',['SET_PLATFORM']),
    ...mapMutations('SearchState',['SET_SEARCH_KEYWORD']),
    ...mapGetters('NavigationState',{
      isSearch: 'isSearch'
    }),
    changeKeyword(keyword){
      this.SET_SEARCH_KEYWORD(keyword)
    },
    changePlatform(platform){
      this.SET_PLATFORM(platform)
    },
    search() {
      if (this.keyword === '') {
        this.isInvalidUsername = true
        return
      }
      if (this.lastKeyword === this.keyword && this.lastPlatform === this.selectedPlatform && this.isSearch)
        return
      this.$router.replace({
        name: 'Search',
        params: {
          platform: this.selectedPlatform,
          keyword: this.keyword
        }
      })
    },
    checkUsername() {
      if (this.keyword !== '')
        this.isInvalidUsername = false
    },renderPlatform(plat) {
      return this.platforms.find(platform => platform.value === plat).html
    },
    uniqueArray(array) {
      let ret = []
      for (let i = 0; i < array.length; i++) {
        let flag = false
        for (let j = 0; j < ret.length; j++)
          if (array[i].platform === ret[j].platform && array[i].name === ret[j].name)
            flag = true
        if (!flag)
          ret.push(array[i])
      }
      return ret
    }
  },
  computed: {
    ...mapState('SearchState',['searchKeyword']),
    ...mapState("PlatformState",['platform', 'platforms']),

  },
  watch: {
    selectedPlatform(newPlatform){
      this.changePlatform(newPlatform)
    },
    platform(newPlatform){
      this.selectedPlatform = newPlatform
    },
    keyword(newKeyword){
      this.changeKeyword(newKeyword)
      this.checkUsername()
    },
    searchKeyword(newKeyword){
      this.keyword = newKeyword
    },

  },
  data(){
    return {
      selectedPlatform: '',
      keyword: '',
      isInvalidUsername: false,
      history: this.uniqueArray(JSON.parse(localStorage.getItem('search-history')) || []),
    }
  },
  mounted() {
    this.selectedPlatform = this.platform
    this.keyword = this.searchKeyword
    this.$store.commit("NavigationState/SET_PAGE",this.$route.name)
    document.title = this.$t('home.title').toString()
  },
}
</script>

<style scoped>
.logo {
  width: 100%;
  height: auto;
}

.history {
  cursor: pointer;
}

</style>
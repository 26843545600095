<template>
  <div class="accordion" role="tablist">
    <season :season="season" v-for="(season,index) in this.seasons" :key="index" namespace="seasons"></season>
  </div>
</template>

<script>
import axios from 'axios'
import Season from './Season'
import {mapState} from "vuex";
export default {
  name: "Seasons",
  props: ['data'],
  data(){
    return {
      seasonsSummary: [],
    }
  },
  computed: {
    ...mapState('ApiState',['apiURL']),
    seasons() {
      if (this.data.rank.summary.all) {
        let ret = []
        let seasonIds = ''
        for (let index in this.data.rank.summary.all.teamRoles.all){
          let season = parseInt(this.data.rank.summary.all.teamRoles.all[index].seasonNumber.substring(1))
          let year = parseInt(this.data.rank.summary.all.teamRoles.all[index].seasonYear.substring(1))
          let id = (year - 1) * 4 + season
          seasonIds = seasonIds + ',' + id
          ret.push({
            all: this.data.rank.summary.all.teamRoles.all[index],
            casual: this.data.rank.summary.casual.teamRoles.all[index],
            ranked: this.data.rank.summary.ranked.teamRoles.all[index],
            unranked: this.data.rank.summary.unranked.teamRoles.all[index],
            seasonId: id,
            summary: undefined
          })
        }
        if (this.seasonsSummary.length === 0)
          this.fetchSummary(seasonIds)
        for (let season of this.seasonsSummary)
          for (let index in ret)
            if (ret[index].seasonId === season.season_id)
              ret[index].summary = season.regions_player_skill_records
        ret.sort((a,b) => {
          let tempA = a.all.seasonYear + a.all.seasonNumber
          let tempB = b.all.seasonYear + b.all.seasonNumber
          return tempA < tempB ? 1 : tempA > tempB ? -1 : 0
        })
        return ret
      }
      return []
    }
  },
  components: {
    Season
  },
  methods: {
    fetchSummary(seasonIds) {
      axios.post(`${this.apiURL}ubi/rank/record`,{
        uuid: this.data.profileUUID,
        platform: this.data.platform,
        seasonIds: seasonIds.length !== 0 ? seasonIds.substring(1) : ''
      }).then (
          response => {
            this.seasonsSummary = response.data.seasons_player_skill_records
          }
      )
    }
  }
}
</script>

<style scoped>

</style>
import Vue from 'vue'

import VueI18n from 'vue-i18n'
// Use i18n plugin
Vue.use(VueI18n);

const dateTimeFormats = {
    'en-US': {
        short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        },
        long: {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
            hour: 'numeric',
            minute: 'numeric'
        }
    },
    'zh-CN': {
        short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        },
        long: {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        }
    }
}


export default new VueI18n({
    dateTimeFormats,
    locale: localStorage.getItem("language") || "zh-CN",
    messages: {
        'zh-CN': require('../assets/langs/zh-CN.json'),
        'en-US': require('../assets/langs/en-US.json')
    }
});
<template>
  <div>
    <b-container fluid>
      <NavigationBar />
      <router-view />
    </b-container>
    <div class="split-space"></div>
    <r-footer />
  </div>
</template>

<script>

import NavigationBar from "./components/NavigationBar";
import { mapMutations } from "vuex"
import RFooter from "@/components/RFooter";
export default {
  name: 'App',
  components: {
    RFooter,
    NavigationBar
  },
  methods: {
    ...mapMutations("NavigationState",["SET_PAGE"])
  },
  mounted() {
    this.SET_PAGE(this.$route.name)
  },
}
</script>

<style>
body {
  background-size:auto 100%;
  background: rgba(17, 9, 9) url("./assets/images/background.png") no-repeat fixed center center;
}

.split-space {
  margin: 140px;
}

</style>

<template>
  <b-container>
    <hr>
    <b-row class="justify-content-center">
      <b-col cols="8">
        <b-alert show :variant="searchVariant">
          <b-button-close @click="$router.replace({
          name: 'Home'
          })" />
          <h4 class="alert-heading">{{ $t(this.searchTitle) }}</h4>
          <i18n :path="searchDescription" tag="p">
            <strong slot="username"> {{$route.params.keyword}}</strong>
            <strong slot="platform" v-if="isValidPlatform" v-html="renderPlatform()"></strong>
          </i18n>
          <hr>
          <b-progress :value="searchProgress" :variant="searchVariant" :animated="isAnimated" class="mt-3" show-progress show-value striped></b-progress>
        </b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios"
import {mapState, mapMutations} from "vuex"

export default {
  name: "Search",
  data(){
    return {
      searchVariant: "info",
      searchProgress: 0,
      searchTitle: "search.searching.title",
      searchDescription: "search.searching.description",
      isAnimated: true,
      isValidPlatform: false
    }
  },
  computed: {
    ...mapState('ApiState',['apiURL']),
    ...mapState('PlatformState',['platforms']),
  },
  methods: {
    ...mapMutations('PlatformState',['SET_LAST_PLATFORM']),
    ...mapMutations('SearchState',['SET_LAST_KEYWORD','SET_SEARCH_KEYWORD','SET_IS_SEARCHING','SET_IS_FOUND']),
    ...mapMutations('NavigationState',['SET_PAGE']),
    initSearch() {
      if (!this.platforms.find(platform => platform.value === this.$route.params.platform)) {
        this.isValidPlatform = false
        this.searchProgress = 100
        this.searchTitle = "search.unknown-platform.title"
        this.searchDescription = "search.unknown-platform.description"
        this.searchVariant = "danger"
        this.isAnimated = false
        return
      }
      this.isValidPlatform = true
      this.searchProgress = 0
      this.searchTitle = "search.searching.title"
      this.searchDescription = "search.searching.description"
      this.searchVariant = "info"
      this.isAnimated = true
      this.SET_IS_FOUND(false)
      this.SET_IS_SEARCHING(true)
      this.SET_LAST_PLATFORM(this.$route.params.platform)
      this.SET_LAST_KEYWORD(this.$route.params.keyword)
      this.searchProgress = 10
      axios.post(`${this.apiURL}ubi/search`,{
        name: this.$route.params.keyword,
        platform: this.$route.params.platform
      }).then(
          response => {
            this.searchProgress = 30
            if (response.data.profiles.length === 0) {
              this.SET_IS_FOUND(false)
              this.SET_IS_SEARCHING(false)
              this.searchProgress = 100
              this.isAnimated = false
              this.searchVariant = "warning"
              this.searchTitle = "search.not-found.title"
              this.searchDescription = "search.not-found.description"
            } else {
              if (!this.$route.params.keyword || !this.$route.params.keyword) {
                this.$router.replace({name: 'Home'})
                return
              }
              this.SET_IS_FOUND(true)
              this.SET_IS_SEARCHING(false)
              this.searchProgress = 100
              this.isAnimated = false
              this.searchVariant = "success"
              this.searchTitle = "search.found.title"
              this.searchDescription = "search.found.description"
              let search_history = JSON.parse(localStorage.getItem('search-history'))||[]
              search_history.unshift({name: this.$route.params.keyword, platform: this.$route.params.platform})
              localStorage.setItem("search-history", JSON.stringify(this.uniqueArray(search_history).slice(0,5)))
              setTimeout(()=>{
                this.$router.replace({
                  name: "Overview",
                  params:{
                    uuid: response.data.profiles[0].userId,
                    platform: this.$route.params.platform
                  }
                })
              },500)
            }
          }
      ).catch(
          ()=>{
            this.SET_IS_FOUND(false)
            this.SET_IS_SEARCHING(false)
            this.searchProgress = 100
            this.isAnimated = false
            this.searchVariant =  "danger"
            this.searchTitle = "search.error.title"
            this.searchDescription = "search.error.description"
          }
      )
    },
    renderPlatform() {
      return this.platforms.find(platform => platform.value === this.$route.params.platform).html
    },
    uniqueArray(array) {
      let ret = []
      for (let i = 0; i < array.length; i++) {
        let flag = false
        for (let j = 0; j < ret.length; j++)
          if (array[i].platform === ret[j].platform && array[i].name === ret[j].name)
            flag = true
        if (!flag)
          ret.push(array[i])
      }
      return ret
    }
  },
  mounted() {
    document.title = this.$t('search.title').toString() + " " + this.$route.params.keyword + " " + this.$t('search.on').toString() + " " + this.$route.params.platform
    this.SET_PAGE(this.$route.name)
    this.SET_SEARCH_KEYWORD(this.$route.params.keyword)
    this.initSearch();
  },
  beforeRouteUpdate(to, from, next) {
    next()
    this.initSearch();
  }
}
</script>

<style scoped>

</style>